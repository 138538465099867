import axios from 'axios';

// Develop
// const baseURL = 'http://localhost:8080/api';

// Production
const baseURL = 'https://dpipos-98e27f874d11.herokuapp.com/api';

const rapiApi = axios.create({ baseURL });

// cafeApi.interceptors.request.use(async (config) => {
//   const token = await AsyncStorage.getItem('token');

//   if (token) {
//     config.headers!['x-token'] = token;
//   }

//   return config;
// });

export default rapiApi;
