import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import rapiApi from '../api/rapiApi';

export const WelcomeScreen = () => {
  const params = useParams();
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params.token) {
      checkToken(params.token);
    }
  }, []);

  const checkToken = async (token) => {
    try {
      const resp = await rapiApi.post(`/auth/checkToken`, { token });
      console.log(resp.data);
      if (resp.data.ok) {
        confirmAccount(token);
        setConfirm(true);
        setIsLoading(false);
      }
    } catch (error) {
      setConfirm(false);
      setIsLoading(false);
      console.log(error);
    }
  };
  const confirmAccount = async (token) => {
    try {
      const resp = await rapiApi.get(`/auth/confirmAccount/${token}`);
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return isLoading ? (
    <div>
      <CircularProgress />
    </div>
  ) : (
    <>
      {confirm ? (
        <div>
          <Typography variant="h5" color="initial">
            Confirmaste tu cuenta
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h5" color="initial">
            La cuenta no se puede confirmar
          </Typography>
        </div>
      )}
    </>
  );
};
