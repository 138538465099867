import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, CircularProgress, Snackbar } from '@mui/material';
import rapiApi from '../api/rapiApi';
import { useNavigate, useParams } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const initForm = {
  password: '',
  passwordConfirm: '',
};

const initSnack = {
  open: false,
  message: '',
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ChangePasswordScreen = () => {
  const params = useParams();
  const [form, setForm] = useState(initForm);
  const navigate = useNavigate();
  const [confirmToken, setConfirmToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snack, setSnack] = useState(initSnack);

  useEffect(() => {
    checkToken(params.token);
  }, []);

  const checkToken = async (token) => {
    try {
      const resp = await rapiApi.post(`/auth/checkToken`, { token });
      console.log(resp);
      setConfirmToken(true);
      setIsLoading(false);
    } catch (error) {
      setConfirmToken(false);
      setIsLoading(false);
      setSnack({ open: true, message: 'Su solicitud ha expirado' });
      console.log(error);
    }
  };

  const resetPassword = async (token, password) => {
    try {
      if (form.password === form.passwordConfirm) {
        const resp = await rapiApi.post(`/auth/resetPassword`, { token, password });
        if (resp.data.ok) {
          navigate('/account/changePasswordSuccess', { replace: true });
        }
      } else {
        setSnack({ open: true, message: 'Las contraseñas no coinciden' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChagePass = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setSnack({ ...snack, open: false, message: '' });
  };

  return (
    <>
      <Typography variant="h5" color="initial" sx={{ mb: 5 }}>
        Recuperar contraseña
      </Typography>
      <Snackbar
        //
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snack.open}
        onClose={handleClose}
        autoHideDuration={6000}
        key={1}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          {confirmToken ? (
            <>
              <TextField type="password" label="Contraseña" name="password" value={form.password} onChange={onChagePass} sx={{ mb: 4, width: '100%' }} />
              <TextField type="password" label="Confirmar contraseña" name="passwordConfirm" value={form.passwordConfirm} onChange={onChagePass} sx={{ mb: 4, width: '100%' }} />
              <Button variant="outlined" onClick={() => resetPassword(params.token, form.password)}>
                Confirmar
              </Button>
            </>
          ) : (
            <Typography variant="h6" color="initial" sx={{ mb: 5 }}>
              Tu solicitud ha expirado
            </Typography>
          )}
        </>
      )}
    </>
  );
};
