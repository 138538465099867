import React from 'react';
import { Typography } from '@mui/material';

export const HomeScreen = () => {
  return (
    <>
      <Typography variant="h5" color="initial">
        Bienvenido a dPipos
      </Typography>
    </>
  );
};
