import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ChangePasswordScreen } from '../screen/ChangePasswordScreen';
import { HomeScreen } from '../screen/HomeScreen';
import { WelcomeScreen } from '../screen/WelcomeScreen';
import logo from '../assets/img/logo.png';
import { PasswordSuccess } from '../screen/PasswordSuccess';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <div className="centerDiv">
        <div className='centerBox'>
          <img src={logo} width={300} alt="logo" style={{marginBottom:30}} />

          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/account/confirm/:token" element={<WelcomeScreen />} />
            <Route path="/account/password/reset/:token" element={<ChangePasswordScreen />} />
            <Route path="/account/changePasswordSuccess" element={<PasswordSuccess />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};
